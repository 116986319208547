import * as React from "react";
import {
  UsersListDataType,
  UsersListVariablesType,
  commitRefetch,
  commitFetchMore
} from "../queries/userListQuery";
import { SortedTableWithPagination } from "../general/SortedTableWithPagination";

interface GeneralUserListProps {
  data: UsersListDataType;
  variables: UsersListVariablesType;
}

const UsersList: React.FC<GeneralUserListProps> = props => {
  const { variables, data } = props;

  const handlePaginationChange = (pageClicked: number) => {
    commitFetchMore(() => {
      return {
        page: pageClicked
      };
    });
  };

  const handleSortClick = (item: string) => {
    const sortBy = item.toUpperCase();
    const sortDirection =
      variables.sortDirection === "ASC" && variables.sortBy === sortBy
        ? "DESC"
        : "ASC";

    commitRefetch(() => {
      return { sortBy, sortDirection };
    });
  };

  return (
    <SortedTableWithPagination
      handlePaginationClick={handlePaginationChange}
      handleSortClick={handleSortClick}
      data={data.usersList.results}
      sortBy={variables.sortBy}
      sortDirection={variables.sortDirection}
      keys={["ID", "Name", "Email", "Phone", "Role"]}
      dataKeys={["id", "name", "email", "phone", "role"]}
      page={variables.page}
      pageCount={data.usersList.pageCount}
    />
  );
};

export { UsersList };
