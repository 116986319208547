import * as React from "react";
import { EventsListProps, commitRefetch } from "../queries/eventListQuery";
import { SortedTableWithPagination } from "./SortedTableWithPagination";
import { Button } from "semantic-ui-react";
import { Link } from "react-router-dom";

const EventsList: React.FC<EventsListProps> = props => {
  const { variables, data } = props;

  const handlePaginationChange = (pageClicked: number) => {
    commitRefetch(() => {
      return {
        page: pageClicked
      };
    });
  };

  const handleSortClick = (item: string) => {
    const sortBy = item.toUpperCase();
    const sortDirection =
      variables.sortDirection === "ASC" && variables.sortBy === sortBy
        ? "DESC"
        : "ASC";

    commitRefetch(() => {
      return { sortBy, sortDirection };
    });
  };

  const editedList = data.eventsList.results.map(item => ({
    ...item,
    organizationName: item.organization.name
  }));

  return (
    <React.Fragment>
      <Link to="/admin/events/new">
        <Button floated="right" positive>
          New Event
        </Button>
      </Link>
      <SortedTableWithPagination
        handlePaginationClick={handlePaginationChange}
        handleSortClick={handleSortClick}
        data={editedList}
        sortBy={variables.sortBy}
        sortDirection={variables.sortDirection}
        keys={["ID", "Name", "Day", "Start Time", "End Time", "Org Name"]}
        dataKeys={[
          "id",
          "name",
          "day",
          "startTime",
          "endTime",
          "organizationName"
        ]}
        page={variables.page}
        pageCount={data.eventsList.pageCount}
        disabled={[false, false, false, true, true, true]}
      />
    </React.Fragment>
  );
};

export { EventsList };
