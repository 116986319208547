import React from "react";
import { useSelector, useDispatch } from "../store";
import { addEmailEventUser, removeEmailEventUser } from "../store/actions";
import {
  INDIVIDUAL_USER,
  IndividualUserDataType
} from "./SearchFields/queries/userSearchQuery";
import { useQuery } from "@apollo/react-hooks";
import { Checkbox, Accordion, Message } from "semantic-ui-react";
import { SetTutorEmailNotes, SetUserEmailNotes } from "./FormFields";

interface IndividualFieldProps {
  id: number;
  checked: boolean;
}

const IndividualField: React.FC<IndividualFieldProps> = props => {
  const dispatch = useDispatch();
  const { data, loading } = useQuery<IndividualUserDataType>(INDIVIDUAL_USER, {
    variables: { id: props.id }
  });

  if (loading) return <Checkbox label="Loading..." />;

  const handleCheckboxChange = () => {
    if (!props.checked) dispatch(addEmailEventUser(props.id));
    else dispatch(removeEmailEventUser(props.id));
  };

  return (
    <div className="field">
      <Checkbox
        label={data.user.name}
        checked={props.checked}
        onChange={handleCheckboxChange}
      />
    </div>
  );
};

interface EmailPreviewProps {
  id: number;
  isActive: boolean;
  index: number;
  handleChange: (num: number) => any;
  eventName: string;
  eventDate: string;
  eventStartTime: string;
  eventEndTime: string;
  userNotes: string;
  tutorNotes: string;
}

interface EmailContentProps {
  eventName: string;
  userName: string;
  eventDate: string;
  eventStartTime: string;
  eventEndTime: string;
  notes: string;
}

const TutorEmail: React.FC<EmailContentProps> = props => {
  return (
    <React.Fragment>
      <h1>Hi there, {props.userName}!</h1>
      <p>
        This is a notification email from Applied Tutoring to let you know that
        an admin just added you to an event. Here's the details:
      </p>
      <ul>
        <li>
          <b>Event name: </b>
          {props.eventName}
        </li>
        <li>
          <b>Event date: </b>
          {props.eventDate}
        </li>
        <li>
          <b>Start time: </b>
          {props.eventStartTime}
        </li>
        <li>
          <b>End time: </b>
          {props.eventEndTime}
        </li>
      </ul>
      {props.notes.length > 0 && (
        <React.Fragment>
          <p>An admin attached these notes:</p>
          <p>{props.notes}</p>
        </React.Fragment>
      )}
      <p>Here's the users that will be at the event:</p>
      <p>
        <em>
          If you've added any users, the tutor will see their contact info in
          the email here.
        </em>
      </p>
      <p>
        If you need to contact any of the clients, just use their contact
        information above. Please be aware that they may reach out to you as
        well.
      </p>
    </React.Fragment>
  );
};

const UserEmail: React.FC<EmailContentProps> = props => {
  return (
    <React.Fragment>
      <h1>Hi there, {props.userName}!</h1>
      <p>
        This is a notification email from Applied Tutoring to let you know that
        we just added an event for you. Here's the details:
      </p>
      <ul>
        <li>
          <b>Event name: </b> {props.eventName}
        </li>
        <li>
          <b>Event date: </b> {props.eventDate}
        </li>
        <li>
          <b>Start time: </b> {props.eventStartTime}
        </li>
        <li>
          <b>End Time: </b> {props.eventEndTime}
        </li>
      </ul>

      {props.notes.length > 0 && (
        <React.Fragment>
          <p>An admin attached these notes:</p>
          <p>{props.notes}</p>
        </React.Fragment>
      )}

      <p>Here's the tutors that will be at the event:</p>
      <p>
        If you've added any tutors, the user will see their contact info in the
        email here.
      </p>
    </React.Fragment>
  );
};

const EmailPreview: React.FC<EmailPreviewProps> = props => {
  const { data, loading } = useQuery<IndividualUserDataType>(INDIVIDUAL_USER, {
    variables: { id: props.id }
  });

  if (loading) return <h3>Loading...</h3>;

  return (
    <React.Fragment>
      <Accordion.Title
        index={props.index}
        active={props.isActive}
        onClick={() => props.handleChange(props.index)}
      >
        Email for: {data.user.name} <em>({data.user.email})</em>
      </Accordion.Title>
      <Accordion.Content active={props.isActive}>
        {data.user.role === "USER" ? (
          <UserEmail
            notes={props.userNotes}
            userName={data.user.name}
            {...props}
          />
        ) : (
          <TutorEmail
            notes={props.tutorNotes}
            userName={data.user.name}
            {...props}
          />
        )}
      </Accordion.Content>
    </React.Fragment>
  );
};

export const EmailFields = () => {
  const {
    usersState: { userIds },
    tutorsState: { tutorIds },
    formFieldState: {
      userEmailIds,
      calculatedDate,
      startTime,
      endTime,
      name,
      userNotes,
      tutorNotes
    }
  } = useSelector(store => store.createEventAdminPage);
  const [activeId, setActiveId] = React.useState(-1);

  const ids = [...Array.from(userIds), ...Array.from(tutorIds)];
  const selectedIds = Array.from(userEmailIds);

  return (
    <React.Fragment>
      <h1>Choose who to send emails to:</h1>
      {userEmailIds.size < 1 && (
        <Message color="yellow">
          <Message.Header>Email notifications</Message.Header>
          <p>
            Note that any user checked here <b>will receive an email.</b> Email
            previews are shown below.
          </p>
          <p>
            It's also important to note that they'll{" "}
            <b>only be emailed this one time</b>. Users won't be emailed again
            if you update or delete an event later unless if you{" "}
            <b>click one of these checkboxes.</b>
          </p>
        </Message>
      )}
      {ids.map(id => (
        <IndividualField id={id} checked={selectedIds.includes(id)} />
      ))}
      {selectedIds.length > 0 && (
        <React.Fragment>
          {userNotes.length === 0 && tutorNotes.length === 0 && (
            <Message color="yellow">
              <Message.Header>Email Notes</Message.Header>
              <p>
                Any notes you add below will be sent to all users added or all
                tutors added. Leave them empty to add no notes.
              </p>
              <p>
                If you need to add a custom message, this is the place to do it.
              </p>
            </Message>
          )}
          <h1>User email notes:</h1>
          <SetUserEmailNotes />
          <h1>Tutor email notes:</h1>
          <SetTutorEmailNotes />
          <h1>Email Previews</h1>
          <Message color="yellow">
            <p>
              You can preview the emails that will be sent below. Click on the
              "Email for:" heading to open and close them.
            </p>
          </Message>
          <Accordion fluid styled>
            {selectedIds.map((id, index) => (
              <EmailPreview
                userNotes={userNotes}
                tutorNotes={tutorNotes}
                eventDate={calculatedDate}
                eventStartTime={startTime}
                eventEndTime={endTime}
                eventName={name}
                handleChange={index => {
                  setActiveId(prev => (prev === index ? -1 : index));
                }}
                index={index}
                id={id}
                isActive={index === activeId}
              />
            ))}
          </Accordion>
        </React.Fragment>
      )}
    </React.Fragment>
  );
};
