import gql from "graphql-tag";

export const TUTOR_SEARCH = gql`
  query TutorSearch($search: String!, $excludeUsers: [ID!]) {
    usersList(
      attributes: {
        limit: 50
        search: $search
        filterByRole: [TUTOR]
        excludeUsers: $excludeUsers
      }
    ) {
      results {
        name
        email
        id
      }
    }
  }
`;

interface TutorSearchType {
  id: number;
  name: string;
  email: string;
}

export interface TutorSearchDataType {
  usersList: {
    results: TutorSearchType[];
  };
}

export interface TutorSearchVariablesType {
  search: string;
}

export const INDIVIDUAL_TUTOR = gql`
  query IndividualTutor($id: ID!) {
    user(id: $id) {
      name
      email
      role
    }
  }
`;

export interface IndividualTutorDataType {
  user: {
    name: string;
    email: string;
    role: string;
  };
}
