import * as React from "react";
import { Pagination, PaginationProps, Table } from "semantic-ui-react";

/**
 * General data type describing table data (trying to write the typing with a generic was just proving to be too hard).
 */
interface DataType {
  [key: string]: any;
}

/**
 * The different properties needed for the table to function.
 */
interface SortedTableWithPaginationProps {
  sortBy: string;
  sortDirection: string;
  page: number;
  pageCount: number;
  keys: string[];
  handleSortClick: (item: string) => any;
  handlePaginationClick: (clickedPage: number) => any;
  data: DataType[];
  dataKeys: string[];
  disabled?: boolean[];
}

/**
 * Takes a given data object and transforms it into a sortable table with pagination.
 * @param props All of the variables that the table needs to function.
 */
function SortedTableWithPagination(props: SortedTableWithPaginationProps) {
  const {
    keys,
    sortBy,
    sortDirection,
    handleSortClick,
    data,
    page,
    pageCount,
    dataKeys
  } = props;

  const handlePaginationChange = (
    e: React.MouseEvent<HTMLAnchorElement, MouseEvent>,
    { activePage }: PaginationProps
  ) => {
    props.handlePaginationClick(
      typeof activePage === "string" ? parseInt(activePage) : activePage
    );
  };

  const handleSortClickGate = (item, index) => {
    if (
      !(
        props.disabled &&
        props.disabled.length &&
        props.disabled.length > index &&
        props.disabled[index]
      )
    ) {
      handleSortClick(item);
    }
  };

  return (
    <Table sortable celled fixed>
      <Table.Header>
        <Table.Row>
          {keys.map((item, index) => (
            <Table.HeaderCell
              key={index}
              sorted={
                sortBy === item.toUpperCase()
                  ? sortDirection === "ASC"
                    ? "ascending"
                    : "descending"
                  : null
              }
              disabled={
                props.disabled &&
                props.disabled.length &&
                props.disabled.length > index &&
                props.disabled[index]
              }
              onClick={() => handleSortClickGate(item, index)}
            >
              {item}
            </Table.HeaderCell>
          ))}
        </Table.Row>
      </Table.Header>
      <Table.Body>
        {data.map((object, index) => (
          <Table.Row key={index}>
            {dataKeys.map(key => (
              <Table.Cell>{object[key]}</Table.Cell>
            ))}
          </Table.Row>
        ))}
      </Table.Body>
      <Table.Footer>
        <Table.Row>
          <Table.HeaderCell colSpan={keys.length}>
            <Pagination
              defaultActivePage={page}
              totalPages={pageCount}
              onPageChange={handlePaginationChange}
            />
          </Table.HeaderCell>
        </Table.Row>
      </Table.Footer>
    </Table>
  );
}

export { SortedTableWithPagination };
