import { useMemo } from "react";
import { useDispatch } from "../store";
import {
  selectEventOrganization,
  selectEventCourse,
  addEventUserSelection,
  addEventTutorSelection,
  clearAllEventData,
  updateEventDate,
  updateEventStartTime,
  updateEventEndTime,
  updateEventName
} from "../store/actions";
import { useParams } from "react-router-dom";
import { useQuery } from "@apollo/react-hooks";
import {
  GET_EVENT_QUERY,
  GetEventQueryDataType,
  GetEventQueryVariablesType
} from "./getEventQuery";

export const useDatafromParams = () => {
  const dispatch = useDispatch();
  const { id } = useParams();
  const { data, loading, error } = useQuery<
    GetEventQueryDataType,
    GetEventQueryVariablesType
  >(GET_EVENT_QUERY, {
    variables: {
      id: parseInt(id)
    }
  });

  console.log(data, loading, error);

  useMemo(() => {
    if (!loading) {
      dispatch(clearAllEventData());
      const { event } = data;
      const {
        tutors,
        users,
        course,
        organization,
        day,
        name,
        startTime,
        endTime
      } = event;

      const {
        pluck: { ids: userIds }
      } = users;
      const {
        pluck: { ids: tutorIds }
      } = tutors;

      userIds.forEach(id => dispatch(addEventUserSelection(id)));
      tutorIds.forEach(id => dispatch(addEventTutorSelection(id)));

      dispatch(selectEventCourse(course.id));
      dispatch(selectEventOrganization(organization.id));
      dispatch(updateEventDate(new Date(day)));
      dispatch(updateEventStartTime(startTime));
      dispatch(updateEventEndTime(endTime));
      dispatch(updateEventName(name));
    }
  }, [data, loading]);
};
