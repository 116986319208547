import gql from "graphql-tag";

export const ORGANIZATION_SEARCH = gql`
  query OrganizationSearch($search: String!) {
    organizationsList(attributes: { limit: 50, search: $search }) {
      results {
        name
        description
        id
      }
    }
  }
`;

interface OrganizationSearchType {
  name: string;
  id: number;
  description: string;
}

export interface OrganizationSearchDataType {
  organizationsList: {
    results: OrganizationSearchType[];
  };
}

export interface OrganizationSearchVariablesType {
  search: string;
}

export const INDIVIDUAL_ORGANIZATION = gql`
  query IndividualOrganization($id: ID!) {
    organization(id: $id) {
      name
      description
    }
  }
`;

interface IndividualOrganizationType {
  name: string;
  description: string;
}

export interface IndividualOrganizationDataType {
  organization: IndividualOrganizationType;
}
