import {
  UPDATE_ORGANIZATION_SEARCH,
  SELECT_EVENT_ORGANIZATION,
  REMOVE_EVENT_ORGANIZATION_SELECTION,
  UPDATE_COURSE_SEARCH,
  SELECT_EVENT_COURSE,
  REMOVE_EVENT_COURSE_SELECTION,
  CreateEventActionType,
  ADD_EVENT_TUTOR_SELECTION,
  ADD_EVENT_USER_SELECTION,
  REMOVE_EVENT_TUTOR_SELECTION,
  REMOVE_EVENT_USER_SELECTION,
  UPDATE_TUTOR_SEARCH,
  UPDATE_USER_SEARCH,
  UPDATE_EVENT_DATE,
  UPDATE_EVENT_START_TIME,
  UPDATE_EVENT_END_TIME,
  UPDATE_EVENT_NAME,
  CLEAR_ALL_EVENT_DATA,
  ADD_EMAIL_EVENT_USER,
  REMOVE_EMAIL_EVENT_USER,
  UPDATE_USER_EMAIL_NOTES,
  UPDATE_TUTOR_EMAIL_NOTES
} from "./types";

export function updateOrganizationSearch(
  searchText: string
): CreateEventActionType {
  return {
    type: UPDATE_ORGANIZATION_SEARCH,
    searchText
  };
}

export function selectEventOrganization(id: number): CreateEventActionType {
  return {
    type: SELECT_EVENT_ORGANIZATION,
    id
  };
}

export function removeEventOrganizationSelection(): CreateEventActionType {
  return {
    type: REMOVE_EVENT_ORGANIZATION_SELECTION
  };
}

export function updateCourseSearch(searchText: string): CreateEventActionType {
  return {
    type: UPDATE_COURSE_SEARCH,
    searchText
  };
}

export function selectEventCourse(id: number): CreateEventActionType {
  return {
    type: SELECT_EVENT_COURSE,
    id
  };
}

export function removeEventCourseSelection(): CreateEventActionType {
  return {
    type: REMOVE_EVENT_COURSE_SELECTION
  };
}

export function addEventTutorSelection(tutorId: number): CreateEventActionType {
  return {
    type: ADD_EVENT_TUTOR_SELECTION,
    tutorId
  };
}

export function removeEventTutorSelection(
  tutorId: number
): CreateEventActionType {
  return {
    type: REMOVE_EVENT_TUTOR_SELECTION,
    tutorId
  };
}

export function updateTutorSearch(searchText: string): CreateEventActionType {
  return {
    type: UPDATE_TUTOR_SEARCH,
    searchText
  };
}

export function addEventUserSelection(userId: number): CreateEventActionType {
  return {
    type: ADD_EVENT_USER_SELECTION,
    userId
  };
}

export function removeEventUserSelection(
  userId: number
): CreateEventActionType {
  return {
    type: REMOVE_EVENT_USER_SELECTION,
    userId
  };
}

export function updateUserSearch(searchText: string): CreateEventActionType {
  return {
    type: UPDATE_USER_SEARCH,
    searchText
  };
}

export function updateEventDate(newDate: Date): CreateEventActionType {
  return {
    type: UPDATE_EVENT_DATE,
    newDate
  };
}

export function updateEventStartTime(
  newStartTime: string
): CreateEventActionType {
  return {
    type: UPDATE_EVENT_START_TIME,
    newStartTime
  };
}

export function updateEventEndTime(newEndTime: string): CreateEventActionType {
  return {
    type: UPDATE_EVENT_END_TIME,
    newEndTime
  };
}

export function updateEventName(name: string): CreateEventActionType {
  return {
    type: UPDATE_EVENT_NAME,
    name
  };
}

export function clearAllEventData(): CreateEventActionType {
  return {
    type: CLEAR_ALL_EVENT_DATA
  };
}

export function addEmailEventUser(userId: number): CreateEventActionType {
  return {
    type: ADD_EMAIL_EVENT_USER,
    userId
  };
}

export function removeEmailEventUser(userId: number): CreateEventActionType {
  return {
    type: REMOVE_EMAIL_EVENT_USER,
    userId
  };
}

export function updateUserEmailNotes(userNotes: string): CreateEventActionType {
  return {
    type: UPDATE_USER_EMAIL_NOTES,
    userNotes
  };
}

export function updateTutorEmailNotes(
  tutorNotes: string
): CreateEventActionType {
  return {
    type: UPDATE_TUTOR_EMAIL_NOTES,
    tutorNotes
  };
}
