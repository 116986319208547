import * as React from "react";
import { Accordion, Icon } from "semantic-ui-react";
import RequestsComponent, { RequestType } from "./RequestsComponent";

interface OrganizationType {
  add_key: string;
  created_at: string;
  updated_at: string;
  description: string;
  id: number;
  name: string;
  requests: RequestType[];
}

interface RequestListComponentProps {
  organizations: OrganizationType[];
}

const RequestListComponent: React.FC<RequestListComponentProps> = props => {
  const [activeIndex, setActiveIndex] = React.useState(0);
  const [organizations, setOrganizations] = React.useState<OrganizationType[]>(
    []
  );

  React.useEffect(() => setOrganizations(props.organizations), [
    props.organizations
  ]);

  const handleClick = (e, titleProps) => {
    const { index } = titleProps;
    const newIndex = activeIndex === index ? -1 : index;
    setActiveIndex(newIndex);
  };

  const onArchive = (orgId: number) => (index: number) => {
    setOrganizations(orgs => {
      orgs[orgId].requests.splice(index, 1);
      return [...orgs];
    });
  };

  return (
    <Accordion fluid styled>
      {organizations.map((organization, index) => (
        <React.Fragment key={index}>
          <Accordion.Title
            active={activeIndex === index}
            index={index}
            onClick={handleClick}
          >
            <Icon name="dropdown" />
            {`${organization.name} - ${organization.requests.length} requests`}
          </Accordion.Title>
          <Accordion.Content active={activeIndex === index}>
            <div>
              <div className="ui buttons">
                <a
                  className="ui green button"
                  href={`/admin/organizations/events/new?organizationID=${organization.id}`}
                  data-turbolinks="false"
                >
                  New Event
                </a>
                <a
                  className="ui primary button"
                  href={`/admin/organizations/${organization.id}`}
                  data-turbolinks="false"
                >
                  View Organization
                </a>
              </div>
              <br />
              <br />
              <RequestsComponent
                onArchive={onArchive(index)}
                requests={organization.requests}
              />
            </div>
          </Accordion.Content>
        </React.Fragment>
      ))}
    </Accordion>
  );
};

export default RequestListComponent;
