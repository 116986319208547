/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

//==============================================================
// START Enums and Input Objects
//==============================================================

export enum SortFieldDirection {
  ASC = "ASC",
  DESC = "DESC",
}

/**
 * The different fields that a organization list can be sorted by.
 */
export enum SortableEventFields {
  CREATED_AT = "CREATED_AT",
  DAY = "DAY",
  END_TIME = "END_TIME",
  ID = "ID",
  NAME = "NAME",
  START_TIME = "START_TIME",
  UPDATED_AT = "UPDATED_AT",
}

/**
 * The different fields that a user list can be sorted by.
 */
export enum SortableUserFields {
  CREATED_AT = "CREATED_AT",
  EMAIL = "EMAIL",
  ID = "ID",
  NAME = "NAME",
  PHONE = "PHONE",
  ROLE = "ROLE",
  UPDATED_AT = "UPDATED_AT",
}

/**
 * The different roles a user can have.
 */
export enum UserRolesEnum {
  ADMIN = "ADMIN",
  TUTOR = "TUTOR",
  USER = "USER",
}

//==============================================================
// END Enums and Input Objects
//==============================================================
