import * as React from 'react'
import { ApolloClient } from 'apollo-client'
import { InMemoryCache } from 'apollo-cache-inmemory'
import { HttpLink } from 'apollo-link-http'
import { onError } from 'apollo-link-error'
import { ApolloLink } from 'apollo-link'
import { ApolloProvider } from '@apollo/react-hooks'
import UsersPage from './UsersPage/index'

const client = new ApolloClient({
  link: ApolloLink.from([
    onError(({ graphQLErrors, networkError }) => {
      if (graphQLErrors)
        graphQLErrors.forEach(({ message, locations, path }) =>
          console.log(
            `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`,
          ),
        );
      if (networkError) console.log(`[Network error]: ${networkError}`);
    }),
    new HttpLink({
      uri: '/graphql',
      credentials: 'same-origin'
    })
  ]),
  defaultOptions: {
    query: {
      fetchPolicy: 'cache-first'
    }
  },
  cache: new InMemoryCache()
});

const App = () => (
  <ApolloProvider client={client}>
    <UsersPage/>
  </ApolloProvider>
)

export default App