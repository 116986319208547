import React, { useState, useMemo } from "react";
import { Search, SearchProps, Message, Label } from "semantic-ui-react";
import { useDebouncedCallback } from "use-debounce";
import { useSelector, useDispatch } from "../../store";
import {
  addEventUserSelection,
  removeEventUserSelection,
  updateUserSearch
} from "../../store/actions";
import { useQuery } from "@apollo/react-hooks";
import {
  USER_SEARCH,
  UserSearchDataType,
  INDIVIDUAL_USER,
  IndividualUserDataType
} from "./queries/userSearchQuery";
import { useSearchOpen } from "./useSearchOpen";

interface SelectedUserProps {
  id: number;
}

const SelectedUser: React.FC<SelectedUserProps> = props => {
  const dispatch = useDispatch();
  const { loading, data, error } = useQuery<IndividualUserDataType>(
    INDIVIDUAL_USER,
    {
      variables: { id: props.id }
    }
  );

  if (loading) return <Message header="Loading..." />;
  if (error || data.user.role !== "USER") {
    dispatch(removeEventUserSelection(props.id));
    return <Message header="Error. Select another organization." />;
  }

  return (
    <Message
      onDismiss={() => dispatch(removeEventUserSelection(props.id))}
      header={`Selected User: ${data.user.name}`}
      content={`Email: ${data.user.email}`}
    />
  );
};

export const UserSearch: React.FC = () => {
  const store = useSelector(state => state.createEventAdminPage.usersState);
  const dispatch = useDispatch();
  const [searchValue, setSearchValue] = useState("");
  const { loading, data } = useQuery<UserSearchDataType>(USER_SEARCH, {
    variables: {
      search: store.searchText,
      excludeUsers: Array.from(store.userIds)
    }
  });
  const openProps = useSearchOpen();

  const [handleSearchChange] = useDebouncedCallback(
    (e: React.MouseEvent<HTMLElement, MouseEvent>, { value }: SearchProps) => {
      dispatch(updateUserSearch(value));
    },
    500
  );

  useMemo(() => {
    if (store.searchText === "") setSearchValue("");
  }, [store.searchText]);

  const usersArray = Array.from(store.userIds);

  let mappedResults = [];
  if (!loading) {
    const { results } = data.usersList;
    mappedResults = results.map(item => ({
      title: item.name,
      description: item.email,
      id: item.id
    }));
  }

  const handleSelection = (
    e: React.SyntheticEvent<HTMLElement, Event>,
    { result }: SearchProps
  ) => {
    dispatch(addEventUserSelection(result.id));
  };

  return (
    <React.Fragment>
      <Label pointing="below">
        Select {store.hasSelected ? "another" : "a"} user
      </Label>
      <Search
        loading={loading}
        onResultSelect={handleSelection}
        onSearchChange={(e, p) => {
          setSearchValue(p.value);
          handleSearchChange(e, p);
        }}
        results={mappedResults}
        value={searchValue}
        noResultsMessage="Couldn't find that user."
        {...openProps}
      />
      {store.hasSelected &&
        usersArray.map(id => <SelectedUser id={id} key={id} />)}
    </React.Fragment>
  );
};
