import gql from "graphql-tag";

export const USER_SEARCH = gql`
  query UserSearch($search: String!, $excludeUsers: [ID!]) {
    usersList(
      attributes: {
        limit: 50
        search: $search
        filterByRole: [USER]
        excludeUsers: $excludeUsers
      }
    ) {
      results {
        name
        email
        id
      }
    }
  }
`;

interface UserSearchType {
  id: number;
  name: string;
  email: string;
  role: string;
}

export interface UserSearchDataType {
  usersList: {
    results: UserSearchType[];
  };
}

export interface UserSearchVariablesType {
  search: string;
}

export const INDIVIDUAL_USER = gql`
  query IndividualUser($id: ID!) {
    user(id: $id) {
      name
      email
      role
    }
  }
`;

export interface IndividualUserDataType {
  user: {
    name: string;
    email: string;
    role: string;
  };
}
