// Action Constants
export const UPDATE_ORGANIZATION_SEARCH = "UPDATE_ORGANIZATION_SEARCH";
export const SELECT_EVENT_ORGANIZATION = "SELECT_EVENT_ORGANIZATION";
export const REMOVE_EVENT_ORGANIZATION_SELECTION =
  "REMOVE_EVENT_ORGANIZATION_SELECTION";
export const UPDATE_COURSE_SEARCH = "UPDATE_COURSE_SEARCH";
export const SELECT_EVENT_COURSE = "SELECT_EVENT_COURSE";
export const REMOVE_EVENT_COURSE_SELECTION = "REMOVE_EVENT_COURSE_SELECTION";
export const ADD_EVENT_USER_SELECTION = "ADD_EVENT_USER_SELECTION";
export const REMOVE_EVENT_USER_SELECTION = "REMOVE_EVENT_USER_SELECTION";
export const UPDATE_USER_SEARCH = "UPDATE_USER_SEARCH";
export const ADD_EVENT_TUTOR_SELECTION = "ADD_EVENT_TUTOR_SELECTION";
export const REMOVE_EVENT_TUTOR_SELECTION = "REMOVE_EVENT_TUTOR_SELECTION";
export const UPDATE_TUTOR_SEARCH = "UPDATE_TUTOR_SEARCH";
export const UPDATE_EVENT_DATE = "UPDATE_EVENT_DATE";
export const UPDATE_EVENT_START_TIME = "UPDATE_EVENT_START_TIME";
export const UPDATE_EVENT_END_TIME = "UPDATE_EVENT_END_TIME";
export const UPDATE_EVENT_NAME = "UPDATE_EVENT_NAME";
export const CLEAR_ALL_EVENT_DATA = "CLEAR_ALL_EVENT_DATA";
export const ADD_EMAIL_EVENT_USER = "ADD_EMAIL_EVENT_USEr";
export const REMOVE_EMAIL_EVENT_USER = "REMOVE_EMAIL_EVENT_USER";
export const UPDATE_USER_EMAIL_NOTES = "UPDATE_USER_EMAIL_NOTES";
export const UPDATE_TUTOR_EMAIL_NOTES = "UPDATE_TUTOR_EMAIL_NOTES";

/* Action Types */

// Organization Types

interface UpdateOrganizationSearchAction {
  type: typeof UPDATE_ORGANIZATION_SEARCH;
  searchText: string;
}

interface SelectEventOrganizationAction {
  type: typeof SELECT_EVENT_ORGANIZATION;
  id: number;
}

interface RemoveEventOrganizationSelectionAction {
  type: typeof REMOVE_EVENT_ORGANIZATION_SELECTION;
}

// Course Types

interface UpdateCourseSearchAction {
  type: typeof UPDATE_COURSE_SEARCH;
  searchText: string;
}

interface SelectEventCourseAction {
  type: typeof SELECT_EVENT_COURSE;
  id: number;
}

interface RemoveEventCourseSelectionAction {
  type: typeof REMOVE_EVENT_COURSE_SELECTION;
}

// User Types

interface AddEventUserSelectionAction {
  type: typeof ADD_EVENT_USER_SELECTION;
  userId: number;
}

interface RemoveEventUserSelectionAction {
  type: typeof REMOVE_EVENT_USER_SELECTION;
  userId: number;
}

interface UpdateUserSearchAction {
  type: typeof UPDATE_USER_SEARCH;
  searchText: string;
}

// Tutor Types

interface AddEventTutorSelectionAction {
  type: typeof ADD_EVENT_TUTOR_SELECTION;
  tutorId: number;
}

interface RemoveEventTutorSelectionAction {
  type: typeof REMOVE_EVENT_TUTOR_SELECTION;
  tutorId: number;
}

interface UpdateTutorSearchAction {
  type: typeof UPDATE_TUTOR_SEARCH;
  searchText: string;
}

// Form types
interface UpdateEventDateAction {
  type: typeof UPDATE_EVENT_DATE;
  newDate: Date;
}

interface UpdateEventStartTimeAction {
  type: typeof UPDATE_EVENT_START_TIME;
  newStartTime: string;
}

interface UpdateEventEndTimeAction {
  type: typeof UPDATE_EVENT_END_TIME;
  newEndTime: string;
}

interface UpdateEventNameAction {
  type: typeof UPDATE_EVENT_NAME;
  name: string;
}

interface ClearAllEventDataAction {
  type: typeof CLEAR_ALL_EVENT_DATA;
}

interface AddEmailEventUserAction {
  type: typeof ADD_EMAIL_EVENT_USER;
  userId: number;
}

interface RemoveEmailEventUserAction {
  type: typeof REMOVE_EMAIL_EVENT_USER;
  userId: number;
}

interface UpdateUserEmailNotesAction {
  type: typeof UPDATE_USER_EMAIL_NOTES;
  userNotes: string;
}

interface UpdateTutorEmailNotesAction {
  type: typeof UPDATE_TUTOR_EMAIL_NOTES;
  tutorNotes: string;
}

// Root Action Type
export type CreateEventActionType =
  | UpdateOrganizationSearchAction
  | SelectEventOrganizationAction
  | RemoveEventOrganizationSelectionAction
  | UpdateCourseSearchAction
  | SelectEventCourseAction
  | RemoveEventCourseSelectionAction
  | AddEventUserSelectionAction
  | RemoveEventUserSelectionAction
  | UpdateUserSearchAction
  | AddEventTutorSelectionAction
  | RemoveEventTutorSelectionAction
  | UpdateTutorSearchAction
  | UpdateEventDateAction
  | UpdateEventStartTimeAction
  | UpdateEventEndTimeAction
  | UpdateEventNameAction
  | ClearAllEventDataAction
  | AddEmailEventUserAction
  | RemoveEmailEventUserAction
  | UpdateUserEmailNotesAction
  | UpdateTutorEmailNotesAction;

// State Type

interface BaseOrganizationStateType {
  searchText: string;
  isSelected: false;
}

export interface SelectedOrganizationStateType {
  searchText: string;
  isSelected: true;
  orgId: number;
}

interface BaseCourseStateType {
  searchText: string;
  isSelected: false;
}

export interface SelectedCourseStateType {
  searchText: string;
  isSelected: true;
  courseId: number;
}

interface SelectedUsersStateType {
  searchText: string;
  hasSelected: boolean;
  userIds: Set<number>;
}

interface SelectedTutorsStateType {
  searchText: string;
  hasSelected: boolean;
  tutorIds: Set<number>;
}

interface FormFieldStateType {
  date: Date;
  calculatedDate: string;
  startTime: string;
  endTime: string;
  name: string;
  userEmailIds: Set<number>;
  userNotes: string;
  tutorNotes: string;
}

export interface CreateEventPageState {
  organizationState: SelectedOrganizationStateType | BaseOrganizationStateType;
  courseState: BaseCourseStateType | SelectedCourseStateType;
  usersState: SelectedUsersStateType;
  tutorsState: SelectedTutorsStateType;
  formFieldState: FormFieldStateType;
}
