import { useState } from "react";

export const useSearchOpen = () => {
  const [open, setOpen] = useState(false);

  return {
    open,
    onFocus: () => setOpen(true),
    onBlur: () => setOpen(false)
  };
};
