import {
  createEventAdminPage,
  initialState as createEventInitialState
} from "./createEventAdminPage/reducers";
import { combineReducers } from "redux";
import { StoreStateType } from "./types";
import { useSelector, TypedUseSelectorHook } from "react-redux";

export const initialState = {
  createEventAdminPage: createEventInitialState
};

export const app = combineReducers<StoreStateType>({
  createEventAdminPage
});

export const useTypedSelector: TypedUseSelectorHook<StoreStateType> = useSelector;
