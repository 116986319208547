import * as React from "react";
import { UsersList } from "./UsersList";
import { FilterBox } from "./FilterBox";
import { wrapComponent, UserListPropType } from "../queries/userListQuery";

const RootUserListComponent: React.FC<UserListPropType> = props => {
  if (props.loading) return <h1>Loading...</h1>;
  return (
    <React.Fragment>
      <FilterBox {...props} />
      <UsersList {...props} />
    </React.Fragment>
  );
};

export default wrapComponent(RootUserListComponent);
