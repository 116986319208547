import gql from "graphql-tag";
import { createQuery, HelperPropType } from "../helpers/graphqlWrapper";

const USERS_LIST = gql`
  query UsersList(
    $page: Int!
    $sortBy: SortableUserFields!
    $sortDirection: SortFieldDirection!
    $filterByRole: [UserRolesEnum!]
  ) {
    usersList(
      attributes: {
        page: $page
        sortBy: $sortBy
        sortDirection: $sortDirection
        filterByRole: $filterByRole
      }
    ) {
      results {
        name
        id
        email
        phone
        role
      }
      page
      pageCount
    }
  }
`;

interface UserType {
  name: string;
  email: string;
  phone: string;
  role: string;
  id: number;
}

export interface UsersListDataType {
  usersList: {
    results: UserType[];
    page: number;
    pageCount: number;
  };
}

export interface UsersListVariablesType {
  page: number;
  sortBy: string;
  sortDirection: string;
  filterByRole: string[];
}

export type UserListPropType = HelperPropType<
  UsersListDataType,
  UsersListVariablesType
>;

const { wrapComponent, commitFetchMore, commitRefetch } = createQuery<
  UsersListDataType,
  UsersListVariablesType
>(USERS_LIST, {
  page: 1,
  sortBy: "ID",
  sortDirection: "ASC",
  filterByRole: []
});

export { wrapComponent, commitFetchMore, commitRefetch };
