import * as React from 'react';
import { Dropdown } from "semantic-ui-react";

interface AvailabilityComponentProps {
    availability: AvailabilityState
    hours: string[]
    onChange: (arg: [string, string]) => any
}

export interface AvailabilityState {
    date: Date
    hours: string[]
    formattedDate?: string
}

const AvailabilityComponent: React.FC<AvailabilityComponentProps> = (props) => {
    const indexOne = props.hours.indexOf(props.availability.hours[0]);
    const indexTwo = props.hours.indexOf(props.availability.hours[1]);

    return <div className="ui message">
        <div className="header">Availability for {props.availability.date.toLocaleDateString("en-US", {weekday: "long", year: "numeric", month: "long", day: "numeric"})}</div>
        <p>Tell us what time you're available on this day. If you're not available on this day, just click on the day in the calendar to remove it.</p>
        <div className="ui two column grid">
            <div className="column">
                <Dropdown
                    placeholder="Select a time..."
                    fluid
                    selection
                    options={props.hours.slice(0, props.hours.length - 2).map((item, index) => ({
                        key: index,
                        value: index,
                        text: item
                    }))}
                    value={indexOne > -1 ? indexOne : false}
                    onChange={(e, data) => {
                        props.onChange([props.hours[data.value as number] || "", props.availability.hours[1]])
                    }}
                    />
            </div>
            <div className="column">
                {props.availability.hours[0].length > 0 &&
                <Dropdown
                  placeholder="Select a time..."
                  fluid
                  selection
                  options={props.hours.map((item, index) => ({
                      key: index,
                      value: index,
                      text: item
                  }))}
                  value={indexTwo > -1 ? indexTwo : false}
                  onChange={(e, data) => {
                      props.onChange([props.availability.hours[0], props.hours[data.value as number] || ""]);
                  }}
                />
                }
            </div>
        </div>
    </div>
};

interface AvailabilityComposerProps {
    availabilityState: AvailabilityState[]
    onChange: (date: Date, data: [string, string]) => any
}

const AvailabilityComponentComposer: React.FC<AvailabilityComposerProps> = (props) => {
    const hours = ['4:00 PM', '4:30 PM', '5:00 PM', '5:30 PM', '6:00 PM', '6:30 PM', '7:00 PM', '7:30 PM', '8:00 PM', '8:30 PM', '9:00 PM', '9:30 PM', '10:00 PM']

    return <React.Fragment>
        {props.availabilityState.sort((a, b) => a.date.getTime() - b.date.getTime()).map((availability, index) => (
            <AvailabilityComponent
                key={index}
                availability={availability}
                hours={hours}
                onChange={(data) => props.onChange(availability.date, data)}
            />
        ))}
    </React.Fragment>
};

export default AvailabilityComponentComposer;