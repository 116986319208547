import React, { useState, useMemo } from "react";
import { Search, SearchProps, Message, Label } from "semantic-ui-react";
import { useDebouncedCallback } from "use-debounce";
import { useSelector, useDispatch } from "../../store";
import {
  addEventTutorSelection,
  removeEventTutorSelection,
  updateTutorSearch
} from "../../store/actions";
import { useQuery } from "@apollo/react-hooks";
import {
  TUTOR_SEARCH,
  TutorSearchDataType,
  INDIVIDUAL_TUTOR,
  IndividualTutorDataType
} from "./queries/tutorSearchQuery";
import { useSearchOpen } from "./useSearchOpen";

interface SelectedTutorProps {
  id: number;
}

const SelectedTutor: React.FC<SelectedTutorProps> = props => {
  const dispatch = useDispatch();
  const { loading, data, error } = useQuery<IndividualTutorDataType>(
    INDIVIDUAL_TUTOR,
    {
      variables: { id: props.id }
    }
  );

  if (loading) return <Message header="Loading..." />;
  if (error || data.user.role !== "TUTOR") {
    dispatch(removeEventTutorSelection(props.id));
    return <Message header="Error. Select another organization." />;
  }

  return (
    <Message
      onDismiss={() => dispatch(removeEventTutorSelection(props.id))}
      header={`Selected Tutor: ${data.user.name}`}
      content={`Email: ${data.user.email}`}
    />
  );
};

export const TutorSearch: React.FC = () => {
  const store = useSelector(state => state.createEventAdminPage.tutorsState);
  const dispatch = useDispatch();
  const [searchValue, setSearchValue] = useState("");
  const { loading, data } = useQuery<TutorSearchDataType>(TUTOR_SEARCH, {
    variables: {
      search: store.searchText,
      excludeUsers: Array.from(store.tutorIds)
    }
  });
  const openProps = useSearchOpen();

  const [handleSearchChange] = useDebouncedCallback(
    (e: React.MouseEvent<HTMLElement, MouseEvent>, { value }: SearchProps) => {
      dispatch(updateTutorSearch(value));
    },
    500
  );

  useMemo(() => {
    if (store.searchText === "") setSearchValue("");
  }, [store.searchText]);

  const tutorsArray = Array.from(store.tutorIds);

  let mappedResults = [];
  if (!loading) {
    const { results } = data.usersList;
    mappedResults = results.map(item => ({
      title: item.name,
      description: item.email,
      id: item.id
    }));
  }

  const handleSelection = (
    e: React.SyntheticEvent<HTMLElement, Event>,
    { result }: SearchProps
  ) => {
    dispatch(addEventTutorSelection(result.id));
  };

  return (
    <React.Fragment>
      {!store.hasSelected && (
        <Message color="red">
          <Message.Header>Select a tutor</Message.Header>
          <p>
            To finish creating an event, you need to select a tutor. Search for
            the tutor above and add them.
          </p>
        </Message>
      )}
      <Label pointing="below">
        Select {store.hasSelected ? "another" : "a"} tutor
      </Label>
      <Search
        loading={loading}
        onResultSelect={handleSelection}
        onSearchChange={(e, p) => {
          setSearchValue(p.value);
          handleSearchChange(e, p);
        }}
        results={mappedResults}
        value={searchValue}
        noResultsMessage="Couldn't find that tutor."
        {...openProps}
      />
      {store.hasSelected &&
        tutorsArray.map(id => <SelectedTutor id={id} key={id} />)}
    </React.Fragment>
  );
};
