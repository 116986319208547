import gql from "graphql-tag";

export const CREATE_EVENT_MUTATION = gql`
  mutation CreateNewEvent(
    $orgId: ID!
    $userIds: [ID!]!
    $name: String!
    $day: Date!
    $startTime: Time!
    $endTime: Time!
    $courseId: ID!
    $emailUserIds: [ID!]
    $userNotes: String
    $tutorNotes: String
  ) {
    createEvent(
      input: {
        eventData: {
          organizationId: $orgId
          userIds: $userIds
          name: $name
          day: $day
          startTime: $startTime
          endTime: $endTime
          courseId: $courseId
          name: $name
          emailUserIds: $emailUserIds
          userEmailNotes: $userNotes
          tutorEmailNotes: $tutorNotes
        }
      }
    ) {
      event {
        id
      }
    }
  }
`;

export interface CreateEventVariablesType {
  orgId: number | string;
  userIds: string[] | number[];
  name: string;
  day: string;
  startTime: string;
  endTime: string;
  courseId: number | string;
  emailUserIds?: number[];
  userNotes?: string;
  tutorNotes?: string;
}
