import * as React from "react";
import { Menu, Label } from "semantic-ui-react";
import {
  commitRefetch,
  UsersListDataType,
  UsersListVariablesType
} from "../queries/userListQuery";

interface FilterBoxProps {
  variables: UsersListVariablesType;
  data: UsersListDataType;
}

const FilterBox: React.FC<FilterBoxProps> = props => {
  const { filterByRole } = props.variables;

  const handleItemClick = (itemClicked: string) => {
    commitRefetch(prev => {
      let { filterByRole } = prev;
      let indexOfRole = filterByRole.indexOf(itemClicked.toUpperCase());

      if (indexOfRole > -1) filterByRole.splice(indexOfRole, 1);
      else filterByRole.push(itemClicked.toUpperCase());

      return { filterByRole: [...filterByRole] };
    });
  };

  return (
    <Menu vertical>
      {["Admin", "User", "Tutor"].map((role, index) => (
        <Menu.Item
          active={filterByRole.indexOf(role.toUpperCase()) > -1}
          onClick={() => handleItemClick(role)}
          name={role.toLowerCase()}
          key={index}
        >
          {role}
        </Menu.Item>
      ))}
    </Menu>
  );
};

export { FilterBox };
