import gql from "graphql-tag";
import { createQuery, HelperPropType } from "../helpers/graphqlWrapper";
import { EventsList } from "./types/EventsList";

const EVENTS_LIST = gql`
  query EventsList(
    $page: Int!
    $sortBy: SortableEventFields!
    $sortDirection: SortFieldDirection!
  ) {
    eventsList(
      attributes: {
        page: $page
        sortBy: $sortBy
        sortDirection: $sortDirection
      }
    ) {
      results {
        id
        name
        startTime
        endTime
        day
        organization {
          name
        }
      }
      page
      pageCount
    }
  }
`;

interface EventsListVariablesType {
  page: number;
  sortBy: string;
  sortDirection: string;
}

export type EventsListProps = HelperPropType<
  EventsList,
  EventsListVariablesType
>;

const { wrapComponent, commitFetchMore, commitRefetch } = createQuery<
  EventsList,
  EventsListVariablesType
>(EVENTS_LIST, {
  page: 1,
  sortBy: "ID",
  sortDirection: "ASC"
});

export { wrapComponent, commitFetchMore, commitRefetch };
