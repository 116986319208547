import * as React from "react";
import { Button, Card, List } from "semantic-ui-react";
import { CopyToClipboard } from "react-copy-to-clipboard";
import * as moment from "moment";
import axios from "axios";

export interface TimeType {
  hour: number;
  minute: number;
  second: number;
  second_of_day: number;
}

export interface AvailabilityType {
  created_at: string;
  day: string;
  start_time: TimeType;
  end_time: TimeType;
  id: number;
  request_id: number;
  updated_at: string;
}

export interface UserType {
  email: string;
  name: string;
  phone: string;
  id: number;
}

export interface CourseType {
  code: number;
  created_at: string;
  id: number;
  prefix: string;
  title: string;
  updated_at: string;
}

export interface RequestType {
  url: string;
  course_id: number;
  created_at: string;
  id: number;
  notes: string;
  organization_id: number;
  updated_at: string;
  user: UserType;
  course: CourseType;
  availabilities: AvailabilityType[];
}

interface RequestComponentProps {
  request: RequestType;
  onArchive: (index: number) => void;
  index: number;
}

const RequestComponent: React.FC<RequestComponentProps> = props => {
  const [phoneCopied, setPhoneCopied] = React.useState(false);
  const [emailCopied, setEmailCopied] = React.useState(false);
  const [allCopied, setAllCopied] = React.useState(false);
  const {
    request: { course, user, availabilities, organization_id }
  } = props;

  const handlePhoneCopy = () => {
    setPhoneCopied(true);
    setTimeout(() => setPhoneCopied(false), 1000);
  };

  const handleEmailCopy = () => {
    setEmailCopied(true);
    setTimeout(() => setEmailCopied(false), 1000);
  };

  const handleAllCopy = () => {
    setAllCopied(true);
    setTimeout(() => setAllCopied(false), 1000);
  };

  const handleRequestDelete = () => {
    axios.delete(props.request.url).then(() => props.onArchive(props.index));
  };

  return (
    <Card>
      <Card.Content>
        <Card.Header>{`${course.prefix} ${course.code}, requested by ${user.name}`}</Card.Header>
      </Card.Content>
      <Card.Content>
        Contact Info for {user.name}
        <List relaxed>
          <List.Item>
            <List.Icon name="phone" verticalAlign="middle" />
            <List.Content>
              <List.Header>Phone: {user.phone}</List.Header>
              <CopyToClipboard onCopy={handlePhoneCopy} text={user.phone}>
                <List.Header as="a">
                  {phoneCopied ? "Copied" : "Copy"}
                </List.Header>
              </CopyToClipboard>
            </List.Content>
          </List.Item>
          <List.Item>
            <List.Icon name="envelope" verticalAlign="middle" />
            <List.Content>
              <List.Header>Phone: {user.email}</List.Header>
              <CopyToClipboard onCopy={handleEmailCopy} text={user.email}>
                <List.Header as="a">
                  {emailCopied ? "Copied" : "Copy"}
                </List.Header>
              </CopyToClipboard>
            </List.Content>
          </List.Item>
          <CopyToClipboard
            onCopy={handleAllCopy}
            text={`Email: ${user.email}\nPhone: ${user.phone}`}
          >
            <Button primary>{allCopied ? "Copied" : "Copy All"}</Button>
          </CopyToClipboard>
        </List>
      </Card.Content>
      <Card.Content>
        Time Slots
        <List bulleted divided>
          {availabilities.map((availability, index) => {
            const startDate = new Date(
              Date.parse(
                `${availability.day} ${availability.start_time.hour}:${availability.start_time.minute}`
              )
            );
            const endDate = new Date(
              Date.parse(
                `${availability.day} ${availability.end_time.hour}:${availability.end_time.minute}`
              )
            );
            const startTime = moment(startDate).format("HH:mm");
            const endTime = moment(endDate).format("HH:mm");
            return (
              <List.Content key={index}>
                <List.Header>{`${startDate.toLocaleDateString("en-US", {
                  weekday: "long",
                  year: "numeric",
                  month: "long",
                  day: "numeric"
                })} from ${startDate.toLocaleTimeString(
                  "en-us"
                )} to ${endDate.toLocaleTimeString("en-us")}`}</List.Header>
                <List.Header
                  as="a"
                  data-turbolinks="false"
                  href={`/admin/events/new?organizationID=${organization_id}&date=${encodeURIComponent(
                    startDate.toString()
                  )}&startTime=${encodeURIComponent(
                    startTime
                  )}&endTime=${encodeURIComponent(endTime)}&courseID=${
                    course.id
                  }&userIDs=[${user.id}]`}
                >
                  Create Event from Slot
                </List.Header>
              </List.Content>
            );
          })}
        </List>
      </Card.Content>
      {props.request.notes && props.request.notes.length > 0 && (
        <Card.Content>
          <h4 className="ui header">Notes</h4>
          {props.request.notes}
        </Card.Content>
      )}
      <Button onClick={handleRequestDelete}>Archive</Button>
    </Card>
  );
};

interface RequestsComponentProps {
  requests: RequestType[];
  onArchive: (index: number) => void;
}

const RequestsComponent: React.FC<RequestsComponentProps> = props => (
  <Card.Group>
    {props.requests.map((request, index) => (
      <RequestComponent
        index={index}
        onArchive={props.onArchive}
        key={index}
        request={request}
      />
    ))}
  </Card.Group>
);

export default RequestsComponent;
