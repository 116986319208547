import * as React from 'react'
import { Dropdown, Checkbox } from 'semantic-ui-react'
import DayPicker from 'react-day-picker'
import axios from 'axios'

interface CourseType {
    id: number
    prefix: string
    title: string
    code: number
    created_at: string
}

interface TutorType {
    name: string
    id: number
}

interface CreateEventFormProps {
    tutors: TutorType[]
    postUrl: string
    putRequest: boolean
    initialValues: {
        tutors: TutorType[]
        date: string
        hours: [string, string]
        name: string
    }
    courses: CourseType[]
}

const hoursArray = ['04:00 PM', '04:30 PM', '05:00 PM', '05:30 PM', '06:00 PM', '06:30 PM', '07:00 PM', '07:30 PM', '08:00 PM', '08:30 PM', '09:00 PM', '09:30 PM', '10:00 PM'];

const CreateEventForm: React.FC<CreateEventFormProps> = (props) => {
    const [tutors, setTutors] = React.useState<number[]>((props.initialValues && props.initialValues.tutors && props.initialValues.tutors.map(item => item.id)) || []);
    const [course, setCourse] = React.useState<number>(props.courses && props.courses.length > 0 && props.courses[0].id);
    const [date, setDate] = React.useState((props.initialValues && props.initialValues.date && new Date(Date.parse(props.initialValues.date))) || null);
    const [hours, setHours] = React.useState<number[]>((props.initialValues && props.initialValues.hours && props.initialValues.hours.map(item => hoursArray.indexOf(item))) || [0, -1]);
    const [name, setName] = React.useState<string>((props.initialValues && props.initialValues.name) || "");
    const [sendEmail, setSendEmail] = React.useState<boolean>(true)

    const handleFormSubmit = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        axios[props.putRequest ? "put" : "post"](props.postUrl, {
            tutors,
            date,
            hours: hours.map(item => hoursArray[item]),
            name,
            course,
            send_email: sendEmail
        }).then(result => eval(result.data))
    }

    return <React.Fragment>
        <form className="ui form" onSubmit={handleFormSubmit}>
            <div className="field">
                <label>Name</label>
                <input type="text" onChange={e => setName(e.target.value)} value={name}/>
            </div>
            <div className="field">
                <label>Date</label>
                <DayPicker
                    selectedDays={date}
                    onDayClick={day => setDate(day)}
                    disabledDays={{before: new Date()}}
                    numberOfMonths={2}
                    pagedNavigation
                    fixedWeeks
                    />
            </div>
            <div className="two fields">
                <div className="field">
                    <label>Start Time</label>
                    <Dropdown
                        placeholder="Select a time..."
                        fluid
                        selection
                        options={hoursArray.slice(0, hoursArray.length - 2).map((item, index) => ({
                            key: index,
                            value: index,
                            text: item
                        }))}
                        value={hours[0]}
                        onChange={(e, data) => setHours(prev => [data.value as number, prev[1]])}
                        />
                </div>
                <div className="field">
                    <label>End Time</label>
                    <Dropdown
                        placeholder="Select a time..."
                        fluid
                        selection
                        options={hoursArray.map((item, index) => ({
                            key: index,
                            value: index,
                            text: item
                        }))}
                        value={hours[1]}
                        onChange={(e, data) => setHours(prev => [prev[0], data.value as number])}
                    />
                </div>
            </div>
            <div className="field">
                <label>Tutors</label>
                <Dropdown
                    placeholder='Select tutors'
                    fluid
                    multiple
                    search
                    selection
                    options={props.tutors.map((tutor, index) => ({
                        key: index,
                        text: tutor.name,
                        value: tutor.id
                    }))}
                    value={tutors}
                    onChange={(e, data) => {
                        setTutors(data.value as number[])
                    }}
                    />
            </div>
            <div className="field">
                <label>Courses</label>
                <Dropdown
                    placeholder="Select courses"
                    fluid
                    search
                    selection
                    options={props.courses.map((course, index) => ({
                        key: index,
                        text: `${course.prefix} ${course.code}`,
                        value: course.id
                    }))}
                    value={course}
                    onChange={(e, data) => {
                        setCourse(data.value as number)
                    }}
                />
            </div>
            <div className="field">
                <Checkbox label="Send email update?" checked={sendEmail} onChange={() => setSendEmail(prev => !prev)} />
            </div>
            <button type="submit" className="ui primary button">{props.putRequest ? "Update" : "Create"} Event</button>
        </form>
    </React.Fragment>
}

export default CreateEventForm
