import React from "react";
import _ from "lodash";
import { Search, SearchProps, Message, Label } from "semantic-ui-react";
import { useSelector, useDispatch } from "../../store";
import {
  updateOrganizationSearch,
  selectEventOrganization,
  removeEventOrganizationSelection
} from "../../store/actions";
import { useQuery } from "@apollo/react-hooks";
import {
  ORGANIZATION_SEARCH,
  OrganizationSearchDataType,
  INDIVIDUAL_ORGANIZATION,
  IndividualOrganizationDataType
} from "./queries/organizationSearchQuery";

interface SelectedOrgProps {
  id: number;
}

const SelectedOrg: React.FC<SelectedOrgProps> = props => {
  const dispatch = useDispatch();
  const { loading, data, error } = useQuery<IndividualOrganizationDataType>(
    INDIVIDUAL_ORGANIZATION,
    {
      variables: { id: props.id }
    }
  );

  if (loading) return <Message header="Loading..." />;
  if (error) {
    dispatch(removeEventOrganizationSelection());
    return <Message header="Error. Select another organization." />;
  }

  return (
    <Message
      onDismiss={() => dispatch(removeEventOrganizationSelection())}
      header={`Selected Organization: ${data.organization.name}`}
      content={data.organization.description}
    />
  );
};

const OrganizationSearch: React.FC = () => {
  const store = useSelector(
    state => state.createEventAdminPage.organizationState
  );
  const dispatch = useDispatch();
  const { loading, data } = useQuery<OrganizationSearchDataType>(
    ORGANIZATION_SEARCH,
    {
      variables: { search: store.searchText }
    }
  );

  let mappedResults = [];
  if (!loading) {
    const { results } = data.organizationsList;
    mappedResults = results.map(item => ({
      title: item.name,
      description: item.description,
      id: item.id
    }));
  }

  const handleSearchChange = _.debounce(
    (e: React.MouseEvent<HTMLElement, MouseEvent>, { value }: SearchProps) => {
      dispatch(updateOrganizationSearch(value));
    },
    500
  );

  const handleSelection = (
    e: React.SyntheticEvent<HTMLElement, Event>,
    { result }: SearchProps
  ) => {
    dispatch(selectEventOrganization(result.id));
  };

  return (
    <React.Fragment>
      {!store.isSelected && (
        <React.Fragment>
          <Label pointing="below">Select an organization</Label>
          <Search
            loading={loading}
            onResultSelect={handleSelection}
            onSearchChange={handleSearchChange}
            results={mappedResults}
            noResultsMessage="Couldn't find that organization."
          />
        </React.Fragment>
      )}
      {store.isSelected && <SelectedOrg id={store.orgId} />}
    </React.Fragment>
  );
};

export { OrganizationSearch };
