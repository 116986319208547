import { useMemo } from "react";
import { useDispatch } from "../store";
import {
  selectEventOrganization,
  selectEventCourse,
  addEventUserSelection,
  addEventTutorSelection,
  clearAllEventData,
  updateEventDate,
  updateEventStartTime,
  updateEventEndTime
} from "../store/actions";
import { useLocation } from "react-router-dom";

const useQuery = () => new URLSearchParams(useLocation().search);

const isNumberArray = (array: any): array is number[] => {
  return Array.isArray(array) && array.every(item => typeof item === "number");
};

export const useDatafromParams = () => {
  const dispatch = useDispatch();
  const query = useQuery();

  useMemo(() => {
    dispatch(clearAllEventData());
    if (query.has("organizationID")) {
      dispatch(selectEventOrganization(parseInt(query.get("organizationID"))));
    }
    if (query.has("courseID")) {
      dispatch(selectEventCourse(parseInt(query.get("courseID"))));
    }
    if (query.has("userIDs")) {
      try {
        const results = JSON.parse(query.get("userIDs"));
        if (isNumberArray(results)) {
          results.forEach(id => dispatch(addEventUserSelection(id)));
        }
      } catch (e) {
        console.log(e);
      }
    }
    if (query.has("tutorIDs")) {
      try {
        const results = JSON.parse(query.get("tutorIDs"));
        if (isNumberArray(results)) {
          results.forEach(id => dispatch(addEventTutorSelection(id)));
        }
      } catch (e) {
        console.log(e);
      }
    }
    if (query.has("startTime")) {
      dispatch(updateEventStartTime(query.get("startTime").toString()));
    }
    if (query.has("endTime")) {
      dispatch(updateEventEndTime(query.get("endTime").toString()));
    }
    if (query.has("date") && Date.parse(query.get("date"))) {
      dispatch(updateEventDate(new Date(query.get("date"))));
    }
  }, []);
};
