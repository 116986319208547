import { createStore } from "redux";
import { app, initialState } from "./reducers";
import {
  useSelector as useSelectorOriginal,
  useDispatch as useDispatchOriginal,
  TypedUseSelectorHook
} from "react-redux";
import { StoreStateType, StoreActionTypes } from "./types";

declare global {
  interface Window {
    __REDUX_DEVTOOLS_EXTENSION__?: (...args: any[]) => any;
    __APOLLO_CLIENT__: any;
  }
}

export const store = createStore<
  StoreStateType,
  StoreActionTypes,
  unknown,
  unknown
>(
  app,
  initialState,
  window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
);

export const useSelector: TypedUseSelectorHook<StoreStateType> = useSelectorOriginal;

export type AppDispatch = typeof store.dispatch;
export const useDispatch: () => AppDispatch = useDispatchOriginal;
