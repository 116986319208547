import {
  CreateEventPageState,
  CreateEventActionType,
  UPDATE_ORGANIZATION_SEARCH,
  SELECT_EVENT_ORGANIZATION,
  REMOVE_EVENT_ORGANIZATION_SELECTION,
  UPDATE_COURSE_SEARCH,
  SELECT_EVENT_COURSE,
  REMOVE_EVENT_COURSE_SELECTION,
  ADD_EVENT_USER_SELECTION,
  REMOVE_EVENT_USER_SELECTION,
  UPDATE_USER_SEARCH,
  ADD_EVENT_TUTOR_SELECTION,
  REMOVE_EVENT_TUTOR_SELECTION,
  UPDATE_TUTOR_SEARCH,
  UPDATE_EVENT_DATE,
  UPDATE_EVENT_START_TIME,
  UPDATE_EVENT_END_TIME,
  UPDATE_EVENT_NAME,
  CLEAR_ALL_EVENT_DATA,
  ADD_EMAIL_EVENT_USER,
  REMOVE_EMAIL_EVENT_USER,
  UPDATE_USER_EMAIL_NOTES,
  UPDATE_TUTOR_EMAIL_NOTES
} from "./types";
import { combineReducers } from "redux";

export const initialState: CreateEventPageState = {
  organizationState: {
    searchText: "",
    isSelected: false
  },
  courseState: {
    searchText: "",
    isSelected: false
  },
  tutorsState: {
    searchText: "",
    hasSelected: false,
    tutorIds: new Set()
  },
  usersState: {
    searchText: "",
    hasSelected: false,
    userIds: new Set()
  },
  formFieldState: {
    date: null,
    calculatedDate: "",
    endTime: "",
    startTime: "",
    name: "",
    userEmailIds: new Set(),
    userNotes: "",
    tutorNotes: ""
  }
};

type OrganizationState = CreateEventPageState["organizationState"];
const { organizationState: orgInitial } = initialState;

function organizationState(
  state = orgInitial,
  action: CreateEventActionType
): OrganizationState {
  switch (action.type) {
    case UPDATE_ORGANIZATION_SEARCH:
      return {
        ...state,
        searchText: action.searchText
      };
    case SELECT_EVENT_ORGANIZATION:
      return {
        ...state,
        isSelected: true,
        orgId: action.id
      };
    case REMOVE_EVENT_ORGANIZATION_SELECTION:
      return {
        searchText: state.searchText,
        isSelected: false
      };
    default:
      return state;
  }
}

type CourseState = CreateEventPageState["courseState"];
const { courseState: courseInitial } = initialState;

function courseState(
  state = courseInitial,
  action: CreateEventActionType
): CourseState {
  switch (action.type) {
    case UPDATE_COURSE_SEARCH:
      return {
        ...state,
        searchText: action.searchText
      };
    case SELECT_EVENT_COURSE:
      return {
        ...state,
        isSelected: true,
        courseId: action.id
      };
    case REMOVE_EVENT_COURSE_SELECTION:
      return {
        searchText: state.searchText,
        isSelected: false
      };
    default:
      return state;
  }
}

type UsersState = CreateEventPageState["usersState"];
const { usersState: usersInitial } = initialState;

function usersState(
  state = usersInitial,
  action: CreateEventActionType
): UsersState {
  switch (action.type) {
    case UPDATE_USER_SEARCH:
      return {
        ...state,
        searchText: action.searchText
      };
    case ADD_EVENT_USER_SELECTION:
      state.userIds.add(action.userId);

      return {
        ...state,
        searchText: "",
        hasSelected: state.userIds.size > 0
      };
    case REMOVE_EVENT_USER_SELECTION:
      state.userIds.delete(action.userId);

      return {
        ...state,
        hasSelected: state.userIds.size > 0
      };
    default:
      return state;
  }
}

type TutorsState = CreateEventPageState["tutorsState"];
const { tutorsState: tutorsInitial } = initialState;

function tutorsState(
  state = tutorsInitial,
  action: CreateEventActionType
): TutorsState {
  switch (action.type) {
    case UPDATE_TUTOR_SEARCH:
      return {
        ...state,
        searchText: action.searchText
      };
    case ADD_EVENT_TUTOR_SELECTION:
      state.tutorIds.add(action.tutorId);

      return {
        ...state,
        searchText: "",
        hasSelected: state.tutorIds.size > 0
      };
    case REMOVE_EVENT_TUTOR_SELECTION:
      state.tutorIds.delete(action.tutorId);

      return {
        ...state,
        hasSelected: state.tutorIds.size > 0
      };
    default:
      return state;
  }
}

type FormFieldState = CreateEventPageState["formFieldState"];
const { formFieldState: formInitial } = initialState;

function formFieldState(
  state = formInitial,
  action: CreateEventActionType
): FormFieldState {
  switch (action.type) {
    case UPDATE_EVENT_DATE:
      return {
        ...state,
        date: action.newDate,
        calculatedDate: `${action.newDate.getMonth() +
          1}-${action.newDate.getDate()}-${action.newDate.getFullYear()}`
      };
    case UPDATE_EVENT_START_TIME:
      return {
        ...state,
        startTime: action.newStartTime
      };
    case UPDATE_EVENT_END_TIME:
      return {
        ...state,
        endTime: action.newEndTime
      };
    case UPDATE_EVENT_NAME:
      return {
        ...state,
        name: action.name
      };
    case ADD_EMAIL_EVENT_USER:
      state.userEmailIds.add(action.userId);
      return {
        ...state,
        userEmailIds: state.userEmailIds
      };
    case REMOVE_EMAIL_EVENT_USER:
      state.userEmailIds.delete(action.userId);
      return {
        ...state,
        userEmailIds: state.userEmailIds
      };
    case UPDATE_USER_EMAIL_NOTES:
      return {
        ...state,
        userNotes: action.userNotes
      };
    case UPDATE_TUTOR_EMAIL_NOTES:
      return {
        ...state,
        tutorNotes: action.tutorNotes
      };
    default:
      return state;
  }
}

export function createEventAdminPage(
  state = initialState,
  action: CreateEventActionType
): CreateEventPageState {
  switch (action.type) {
    case CLEAR_ALL_EVENT_DATA:
      return initialState;
    default:
      return {
        formFieldState: formFieldState(state.formFieldState, action),
        tutorsState: tutorsState(state.tutorsState, action),
        usersState: usersState(state.usersState, action),
        courseState: courseState(state.courseState, action),
        organizationState: organizationState(state.organizationState, action)
      };
  }
}
