import React from "react";
import DayPicker from "react-day-picker";
import { useSelector, useDispatch } from "../store";
import {
  updateEventDate,
  updateEventStartTime,
  updateEventEndTime,
  updateEventName,
  updateUserEmailNotes,
  updateTutorEmailNotes
} from "../store/actions";
import { Input, TextArea } from "semantic-ui-react";

export const SelectDate = () => {
  const date = useSelector(
    store => store.createEventAdminPage.formFieldState.date
  );
  const dispatch = useDispatch();

  const handleDateChange = (day: Date) => {
    dispatch(updateEventDate(day));
  };

  return <DayPicker selectedDays={date} onDayClick={handleDateChange} />;
};

export const SelectStartTime = () => {
  const startTime = useSelector(
    store => store.createEventAdminPage.formFieldState.startTime
  );
  const dispatch = useDispatch();

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    dispatch(updateEventStartTime(e.target.value));
  };

  return <Input type="time" value={startTime} onChange={handleInputChange} />;
};

export const SelectEndTime = () => {
  const endTime = useSelector(
    store => store.createEventAdminPage.formFieldState.endTime
  );
  const dispatch = useDispatch();

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    dispatch(updateEventEndTime(e.target.value));
  };

  return <Input type="time" value={endTime} onChange={handleInputChange} />;
};

export const SetEventName = () => {
  const name = useSelector(
    store => store.createEventAdminPage.formFieldState.name
  );
  const dispatch = useDispatch();

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    dispatch(updateEventName(e.target.value));
  };

  return <Input type="text" value={name} onChange={handleInputChange} />;
};

export const SetUserEmailNotes = () => {
  const notes = useSelector(
    store => store.createEventAdminPage.formFieldState.userNotes
  );
  const dispatch = useDispatch();

  return (
    <TextArea
      value={notes}
      onChange={e => dispatch(updateUserEmailNotes(e.currentTarget.value))}
    />
  );
};

export const SetTutorEmailNotes = () => {
  const notes = useSelector(
    store => store.createEventAdminPage.formFieldState.tutorNotes
  );
  const dispatch = useDispatch();

  return (
    <TextArea
      value={notes}
      onChange={e => dispatch(updateTutorEmailNotes(e.currentTarget.value))}
    />
  );
};
