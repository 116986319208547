import React from "react";
import { Search, SearchProps, Message, Label } from "semantic-ui-react";
import { useSelector, useDispatch } from "../../store";
import {
  selectEventCourse,
  removeEventCourseSelection,
  updateCourseSearch
} from "../../store/actions";
import { useQuery } from "@apollo/react-hooks";
import {
  COURSE_SEARCH,
  CourseSearchDataType,
  INDIVIDUAL_COURSE,
  IndividualCourseDataType
} from "./queries/courseSearchQuery";
import { useDebouncedCallback } from "use-debounce/lib";

interface SelectedOrgProps {
  id: number;
}

const SelectedCourse: React.FC<SelectedOrgProps> = props => {
  const dispatch = useDispatch();
  const { loading, data, error } = useQuery<IndividualCourseDataType>(
    INDIVIDUAL_COURSE,
    {
      variables: { id: props.id }
    }
  );

  if (loading) return <Message header="Loading..." />;
  if (error) {
    dispatch(removeEventCourseSelection());
    return <Message header="Error. Select another organization." />;
  }

  return (
    <Message
      onDismiss={() => dispatch(removeEventCourseSelection())}
      header={`Selected Course: ${data.course.name}`}
    />
  );
};

export const CourseSearch: React.FC = () => {
  const store = useSelector(state => state.createEventAdminPage.courseState);
  const dispatch = useDispatch();
  const { loading, data } = useQuery<CourseSearchDataType>(COURSE_SEARCH, {
    variables: { search: store.searchText }
  });

  const [handleSearchChange] = useDebouncedCallback(
    (e: React.MouseEvent<HTMLElement, MouseEvent>, { value }: SearchProps) => {
      dispatch(updateCourseSearch(value));
    },
    500
  );

  let mappedResults = [];
  if (!loading) {
    const { results } = data.coursesList;
    mappedResults = results.map(item => ({
      title: item.name,
      id: item.id
    }));
  }

  const handleSelection = (
    e: React.SyntheticEvent<HTMLElement, Event>,
    { result }: SearchProps
  ) => {
    dispatch(selectEventCourse(result.id));
  };

  return (
    <React.Fragment>
      {!store.isSelected && (
        <React.Fragment>
          <Label pointing="below">Select an course</Label>
          <Search
            loading={loading}
            onResultSelect={handleSelection}
            onSearchChange={handleSearchChange}
            results={mappedResults}
            noResultsMessage="Couldn't find that course."
          />
        </React.Fragment>
      )}
      {store.isSelected && <SelectedCourse id={store.courseId} />}
    </React.Fragment>
  );
};
