import gql from "graphql-tag";

export const UPDATE_EVENT_MUTATION = gql`
  mutation UpdateExistingEvent(
    $id: ID!
    $orgId: ID
    $userIds: [ID!]
    $name: String
    $day: Date
    $startTime: Time
    $endTime: Time
    $courseId: ID
    $emailUserIds: [ID!]
    $userNotes: String
    $tutorNotes: String
  ) {
    updateEvent(
      input: {
        eventData: {
          organizationId: $orgId
          userIds: $userIds
          name: $name
          day: $day
          startTime: $startTime
          endTime: $endTime
          courseId: $courseId
          name: $name
          emailUserIds: $emailUserIds
          userEmailNotes: $userNotes
          tutorEmailNotes: $tutorNotes
        }
        id: $id
      }
    ) {
      event {
        id
      }
    }
  }
`;

export interface UpdateEventVariablesType {
  id: number | string;
  orgId?: number | string;
  userIds?: string[] | number[];
  name?: string;
  day?: string;
  startTime?: string;
  endTime?: string;
  courseId?: number | string;
  emailUserIds?: number[];
  userNotes?: string;
  tutorNotes?: string;
}
