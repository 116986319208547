import * as React from "react"
import { Form, Dropdown, Button, TextArea } from 'semantic-ui-react'
import DayPicker, { DateUtils } from 'react-day-picker'
import AvailabilityComponent, { AvailabilityState } from './AvailabilityComponent';
import axios from 'axios';

interface ClassType {
    code: number
    id: number
    prefix: string
    title: string
}

interface RequestMakerComponentTypes {
    classes: ClassType[]
    submitURL: string
    disabledDays?: number[]
}

const RequestMakerComponent: React.FC<RequestMakerComponentTypes> = (props) => {
    const [availabilityState, setAvailabilityState] = React.useState<AvailabilityState[]>([]);
    const [selectedClass, setClass] = React.useState(null);
    const [notes, setNotes] = React.useState("");
    let optionsArray = [];
    const safeToSubmit = (availabilityState.length !== 0 && availabilityState.every(item => (
        item.hours[0] !== null && item.hours[0].length > 0)
        && (item.hours[1] !== null && item.hours[1].length > 0)));

    props.classes.forEach(item => {
        optionsArray.push({
            key: item.id,
            value: item.id,
            text: `${item.prefix} ${item.code} - ${item.title}`
        })
    });

    const handleDayClick = (day: Date, { selected }: any) => {
        if (selected) {
            const selectedIndex = availabilityState.findIndex(selectedDay => DateUtils.isSameDay(selectedDay.date, day));
            availabilityState.splice(selectedIndex, 1);
        }
        else {
            availabilityState.push({
                date: day,
                hours: ["", ""]
            });
        }
        setAvailabilityState([...availabilityState])
    };

    const handleFormSubmit = () => {
        axios.post(props.submitURL, {
            class: selectedClass,
            notes,
            availability: availabilityState
        }).then(result => eval(result.data))
    }

    return <React.Fragment>
        <Form>
            {
                selectedClass === null &&
                    <div className="ui message">
                      <div className="header">Start by selecting a class</div>
                        <p>To create a request, start by selecting one of your classes below.</p>
                    </div>
            }
            {
                (selectedClass && availabilityState.length === 0) &&
                    <div className="ui message">
                      <div className="header">Select the days that you're available on.</div>
                      <p>In order for us to schedule a tutor for you, we need to know what days you're available on. Select the days on the calendar below that you're available on.</p>
                    </div>
            }
            {
                (selectedClass && availabilityState.length > 0 && !safeToSubmit) &&
                    <div className="ui message">
                      <div className="header">Select the times you're available on.</div>
                      <p>In order to finish creating your request, we need to know what times you're available on the days you selected. Fill in those times below.</p>
                    </div>

            }
            <Form.Field>
                <label>Select a class</label>
                <Dropdown
                    placeholder="Select a class"
                    fluid
                    search
                    selection
                    options={optionsArray}
                    onChange={(e, data) => setClass(data.value)}
                    />
            </Form.Field>
            {
                selectedClass &&
                    <div className="ui stackable two column grid">
                      <div className="column">
                        <DayPicker
                          selectedDays={availabilityState.map(availability => availability.date)}
                          onDayClick={handleDayClick}
                          disabledDays={[{before: new Date()}, {daysOfWeek: props.disabledDays}]}
                          numberOfMonths={2}
                          pagedNavigation
                          fixedWeeks
                        />
                      </div>
                      <div className="column">
                        <AvailabilityComponent
                          availabilityState={availabilityState}
                          onChange={(date, data) => {
                              const selectedIndex = availabilityState.findIndex(availability => DateUtils.isSameDay(availability.date, date));
                              availabilityState[selectedIndex].hours = [...data];
                              setAvailabilityState([...availabilityState])
                          }}
                        />
                      </div>
                    </div>
            }
            <div className="field">
                <label>Notes</label>
                <TextArea onChange={(event, data) => setNotes(data.value.toString())} value={notes} placeholder="Notes for our scheduler"/>
            </div>
            <Button primary disabled={!safeToSubmit} onClick={handleFormSubmit}>Create Request</Button>
        </Form>
    </React.Fragment>
};

export default RequestMakerComponent
