import React, { useMemo } from "react";
import {
  OrganizationSearch,
  CourseSearch,
  UserSearch,
  TutorSearch
} from "./SearchFields";
import { useSelector } from "../store";
import {
  SelectDate,
  SelectStartTime,
  SelectEndTime,
  SetEventName
} from "./FormFields";
import { EmailFields } from "./EmailFields";
import { useDatafromParams } from "./useDataFromParams";
import { Button, Grid } from "semantic-ui-react";
import { useMutation } from "@apollo/react-hooks";
import {
  UPDATE_EVENT_MUTATION,
  UpdateEventVariablesType
} from "./updateEventMutation";
import {
  SelectedCourseStateType,
  SelectedOrganizationStateType
} from "../store/createEventAdminPage/types";
import { Redirect, useParams } from "react-router-dom";

export const EditEventsPage = () => {
  const {
    courseState,
    formFieldState,
    organizationState,
    tutorsState,
    usersState
  } = useSelector(store => store.createEventAdminPage);
  const { id } = useParams();
  useDatafromParams();
  const [updateEvent, { loading, called }] = useMutation<
    { id: number },
    UpdateEventVariablesType
  >(UPDATE_EVENT_MUTATION);

  if (called && !loading) {
    return <Redirect to="/admin/events" />;
  }

  const canBeSubmitted =
    courseState.isSelected &&
    organizationState.isSelected &&
    tutorsState.hasSelected &&
    usersState.hasSelected &&
    formFieldState.calculatedDate.length > 0 &&
    formFieldState.startTime.length > 0 &&
    formFieldState.endTime.length > 0;

  const handleSubmit = () => {
    updateEvent({
      variables: {
        id,
        courseId: (courseState as SelectedCourseStateType).courseId.toString(),
        orgId: (organizationState as SelectedOrganizationStateType).orgId.toString(),
        userIds: [
          ...Array.from(tutorsState.tutorIds),
          ...Array.from(usersState.userIds)
        ],
        day: formFieldState.calculatedDate,
        startTime: formFieldState.startTime,
        endTime: formFieldState.endTime,
        name: formFieldState.name,
        emailUserIds: Array.from(formFieldState.userEmailIds),
        tutorNotes: formFieldState.tutorNotes,
        userNotes: formFieldState.userNotes
      }
    });
  };

  return (
    <React.Fragment>
      <h1>Create New Event</h1>
      <Grid stackable>
        <Grid.Row>
          <Grid.Column width={8}>
            <h2>Organization</h2>
            <OrganizationSearch />
          </Grid.Column>
          <Grid.Column width={8}>
            <h2>Course</h2>
            <CourseSearch />
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column width={8}>
            <h2>Users</h2>
            <UserSearch />
          </Grid.Column>
          <Grid.Column width={8}>
            <h2>Tutors</h2>
            <TutorSearch />
          </Grid.Column>
        </Grid.Row>
      </Grid>
      <Grid stackable columns={2}>
        <Grid.Row>
          <Grid.Column>
            <h2>Name</h2>
            <SetEventName />
          </Grid.Column>
          <Grid.Column>
            <h2>Date</h2>
            <SelectDate />
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column>
            <h2>Start Time</h2>
            <SelectStartTime />
          </Grid.Column>
          <Grid.Column>
            <h2>End Time</h2>
            <SelectEndTime />
          </Grid.Column>
        </Grid.Row>
      </Grid>
      <EmailFields />
      <br />
      <br />
      <Button disabled={!canBeSubmitted} primary onClick={handleSubmit}>
        Create Event
      </Button>
    </React.Fragment>
  );
};
