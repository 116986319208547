import gql from "graphql-tag";

export const COURSE_SEARCH = gql`
  query CourseSearch($search: String!) {
    coursesList(attributes: { limit: 50, search: $search }) {
      results {
        name
        id
      }
    }
  }
`;

interface CourseSearchType {
  id: number;
  name: string;
}

export interface CourseSearchDataType {
  coursesList: {
    results: CourseSearchType[];
  };
}

export interface CourseSearchVariablesType {
  search: string;
}

export const INDIVIDUAL_COURSE = gql`
  query IndividualCourse($id: ID!) {
    course(id: $id) {
      name
    }
  }
`;

export interface IndividualCourseDataType {
  course: {
    name: string;
  };
}
