import * as React from "react";
import { ApolloError, ApolloQueryResult } from "apollo-client";
import { Query } from "@apollo/react-components";
import { DocumentNode } from "graphql";
import { Subtract } from "utility-types";

export interface HelperPropType<DataType, VariablesType> {
  data: DataType;
  variables: VariablesType;
  loading: boolean;
  error: ApolloError;
}

type ApolloRefetchType<T extends {}> = (
  variables?: T
) => Promise<ApolloQueryResult<any>>;

function createQuery<TData, TVariables>(
  gql: DocumentNode,
  variables: TVariables
) {
  let refetchGlobal: ApolloRefetchType<TVariables>;
  let fetchMoreGlobal: any;
  let globalVariables: TVariables = variables;

  type InjectComponentType = HelperPropType<TData, TVariables>;

  /**
   * Wraps a given component and injects the `data`, `variables`, `loading`, and `error` variables.
   * @param ShowComponent The component to wrap.
   */
  function wrapComponent<ComponentPropType extends InjectComponentType>(
    ShowComponent: React.ComponentType<ComponentPropType>
  ) {
    return (props: Subtract<ComponentPropType, InjectComponentType>) => {
      return (
        <Query<TData> query={gql} variables={variables}>
          {results => {
            refetchGlobal = results.refetch;
            fetchMoreGlobal = results.fetchMore;

            return (
              <ShowComponent
                {...(props as ComponentPropType)}
                error={results.error}
                loading={results.loading}
                data={results.data}
                variables={results.variables as TVariables}
              />
            );
          }}
        </Query>
      );
    };
  }

  /**
   * Commits a refetch in Apollo. Best for most use cases.
   * @param variablesReducer A reducer for deriving new variables.
   */
  function commitRefetch(
    variablesReducer: (prev: TVariables) => Partial<TVariables>
  ) {
    globalVariables = {
      ...globalVariables,
      ...variablesReducer(globalVariables)
    };
    refetchGlobal(globalVariables);
  }

  /**
   * Commits a fetchMore in Apollo. Best for pagination. May not cache well if used for paged pagination.
   * @param variablesReducer A reducer for deriving new variables.
   * @param updateQueryReducer A reducer for merging the old query results with the new ones.
   */
  function commitFetchMore(
    variablesReducer: (prev?: TVariables) => Partial<TVariables>,
    updateQueryReducer?: (previous: TData, newData: TData) => TData
  ) {
    globalVariables = {
      ...globalVariables,
      ...variablesReducer(globalVariables)
    };

    fetchMoreGlobal({
      variables: globalVariables,
      updateQuery: (prev, { fetchMoreResult }) => {
        if (updateQueryReducer)
          return updateQueryReducer(prev, fetchMoreResult);
        else return { ...fetchMoreResult };
      }
    });
  }

  return { wrapComponent, commitRefetch, commitFetchMore };
}

export { createQuery };
