import * as React from "react";
import { ApolloClient } from "apollo-client";
import { InMemoryCache } from "apollo-cache-inmemory";
import { HttpLink } from "apollo-link-http";
import { onError } from "apollo-link-error";
import { ApolloLink } from "apollo-link";
import { ApolloProvider } from "@apollo/react-hooks";
import EventsPage from "./EventsPage/index";
import { NewEventsPage } from "./NewEventsPage";
import { EditEventsPage } from "./EditEventsPage";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { store as appStore } from "./store";
import { Provider } from "react-redux";

const client = new ApolloClient({
  link: ApolloLink.from([
    onError(({ graphQLErrors, networkError }) => {
      if (graphQLErrors)
        graphQLErrors.forEach(({ message, locations, path }) =>
          console.log(
            `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`
          )
        );
      if (networkError) console.log(`[Network error]: ${networkError}`);
    }),
    new HttpLink({
      uri: "/graphql",
      credentials: "same-origin"
    })
  ]),
  defaultOptions: {
    query: {
      fetchPolicy: "cache-first"
    }
  },
  connectToDevTools: true,
  cache: new InMemoryCache()
});

window.__APOLLO_CLIENT__ = client;

const App = () => (
  <Router>
    <Provider store={appStore}>
      <ApolloProvider client={client}>
        <Switch>
          <Route path="/admin/events/new">
            <NewEventsPage />
          </Route>
          <Route path="/admin/events/:id">
            <EditEventsPage />
          </Route>
          <Route path="/admin/events">
            <EventsPage />
          </Route>
        </Switch>
      </ApolloProvider>
    </Provider>
  </Router>
);

export default App;
