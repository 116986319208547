import gql from "graphql-tag";

export const GET_EVENT_QUERY = gql`
  query GetEventQuery($id: ID!) {
    event(id: $id) {
      tutors {
        pluck {
          ids
        }
      }
      users {
        pluck {
          ids
        }
      }
      organization {
        id
      }
      course {
        id
      }
      day
      startTime
      endTime
      name
    }
  }
`;

export interface GetEventQueryDataType {
  event: {
    tutors: {
      pluck: {
        ids: number[];
      };
    };
    users: {
      pluck: {
        ids: number[];
      };
    };
    day: string;
    startTime: string;
    endTime: string;
    name: string;
    organization: {
      id: number;
    };
    course: {
      id: number;
    };
  };
}

export interface GetEventQueryVariablesType {
  id: number;
}
