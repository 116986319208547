import * as React from "react";
import { EventsList } from "./EventsList";
import { wrapComponent, EventsListProps } from "../queries/eventListQuery";

const RootEventListComponent: React.FC<EventsListProps> = props => {
  if (props.error) return <h1>There was an error fetching the table.</h1>;
  if (props.loading) return <h1>Loading...</h1>;

  return <EventsList {...props} />;
};

export default wrapComponent(RootEventListComponent);
